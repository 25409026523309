import React from 'react';
import load from '../../icons/preloader.svg';
import style from './Loader.module.css';

function Loader(props) {
    const cName = props.cName;
    return (
        <div className={` ${style.backDiv} ${cName}`}>
            <img src={load} className={style.loaderImg} alt="loading" />
        </div>
         
    );
}

export default Loader;