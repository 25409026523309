import React, { useEffect, useState } from "react";
import closeIcon from './icons/Icon_close.svg';
const OpenInSafariPrompt = () => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Detect if the device is iOS (iPhone only)
    const isIphone = /iPhone/i.test(userAgent);

    if (isIphone) {
      setShowMessage(true);

      // Hide the message after 15 seconds
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000); // 5 seconds = 5000

      // Cleanup the timer when the component is unmounted
      return () => clearTimeout(timer);
    }
  }, []);

  // Function to close the popup manually
  const closePopup = () => {
    setShowMessage(false);
  };

  return (
    <>
      {showMessage && (
        <div
          style={{
            boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.2)", 
            transition: "bottom 0.3s ease-in-out",       
            padding: "40px 20px 0px",                    
            boxSizing: "border-box",                     
            borderTopRightRadius: "40px",                
            borderTopLeftRadius: "40px", 
            backgroundColor: "#f6f6f6",  // Background color with 50% opacity (rgba format)
            
            textAlign: "center",                         
            position: "fixed",                           
            width: "100%",                               
            bottom: 0,                                   
            zIndex: 1000,
            color: "#666666",
            fontSize: "15px"
          }}
        >
          {/* Close button */}
          <button
            onClick={closePopup}
            style={{
              position: "absolute",
              top: "10px",
              right: "20px",
              backgroundColor: "transparent",
              border: "none",
              fontSize: "15px",
              fontWeight: "bold",
              cursor: "pointer",
              color: "#333333"
            }}
          >
            CLOSE
          </button>
          <p style={{ textAlign: "center" }}>
            <img src={closeIcon} alt="" style={{ width: "40%" }} />
          </p>
          <p style={{
              textTransform: "uppercase"
            }}
            >Payment issues may occur using the code scanner.</p>
          <p>Please use the <strong>Camera app</strong>.</p>
          <p style={{
              backgroundColor: "#fcf4eb",
              padding: "15px"
            }}
            >Or tap the Safari icon for a better experience.</p>
          <br />
        </div>
      )}
    </>
  );
};

export default OpenInSafariPrompt;
