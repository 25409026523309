import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import NavBar from "./commonComponent/Drawer/NavBar";
import Loader from "./commonComponent/Loader/Loader";
import OpenInSafariPrompt from "./OpenInSafariPrompt"; // Import the prompt component

const Home = lazy(() => import("./screens/Home"));
const SuccessFail = lazy(() => import("./screens/SuccessFail"));
const Success = lazy(() => import("./screens/SuccessFail/component/OtherSuccess"));
const Fail = lazy(() => import("./screens/SuccessFail/component/Fail"));
const Terms = lazy(() => import("./screens/Compliance"));
const Upi = lazy(() => import("./screens/UpiPayment"));
const Offer = lazy(() => import("./screens/Offer"));

export default function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/ptresponse/"
          element={
            <Suspense fallback={<Loader />}>
              <SuccessFail />
            </Suspense>
          }
        />
        <Route
          path="/success/"
          element={
            <Suspense fallback={<Loader />}>
              {/* Apply the OpenInSafariPrompt to display the prompt on this route */}
              <>
                <Success />
              </>
            </Suspense>
          }
        />
        <Route
          path="/fail/"
          element={
            <Suspense fallback={<Loader />}>
              <Fail />
            </Suspense>
          }
        />
        <Route
          path="/:machineType/:mId/"
          element={
            <Suspense fallback={<Loader />}>
              <>
              <OpenInSafariPrompt />
              <Home />
              </>
            </Suspense>
          }
        />
        <Route>
          <Route element={<WithNavbar />}>
            <Route
              path="/compliance/:status/"
              element={
                <Suspense fallback={<Loader />}>
                  <Terms />
                </Suspense>
              }
            />
            <Route
              path="/upi/"
              element={
                <Suspense fallback={<Loader />}>
                  <Upi />
                </Suspense>
              }
            />
            <Route
              path="/login/"
              element={
                <Suspense fallback={<Loader />}>
                  <Offer />
                </Suspense>
              }
            />
            <Route
              path="/offers/:offerType/"
              element={
                <Suspense fallback={<Loader />}>
                  <Offer />
                </Suspense>
              }
            />
            <Route
              path="/offers/"
              element={
                <Suspense fallback={<Loader />}>
                  <Offer />
                </Suspense>
              }
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function WithNavbar() {
  return (
    <>
      <NavBar />
      <Outlet />
    </>
  );
}
