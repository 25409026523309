import Home from "../../icons/home-icon.png";
import PoGrn from "../../icons/po-grn-list1.svg";
import BarChartIcon from "../../icons/bars-chart.svg";
import CreateIcon from "../../icons/create.svg";
import UserIcon from "../../icons/user-white.svg";
import ItemsIcon from "../../icons/quality-white.png"
import RipeningIcon from "../../icons/ripening-icon.svg"
import MangoIcon from "../../icons/documents.svg"
import B2bIcon from "../../icons/b2b_icon.svg"

export const SidebarData = [
  {
    title: "ABOUT US",
    path: "/compliance/about/",
    getSlot: false,
    image: Home,
    subListClass: "hide",
    subList: []
  },
  {
    title: "TERMS AND CONDITIONS",
    path: "/compliance/tnc/",
    getSlot: true,
    image: BarChartIcon,
    subListClass: "hide",
    subList: []
  },
  {
    title: "PRIVACY POLICY",
    path: "/compliance/privacy/",
    getSlot: true,
    image: RipeningIcon,
    subListClass: "hide",
    subList: []
  }, 
  {
    title: "REFUND POLICY",
    path: "/compliance/refund/",
    getSlot: true,
    image: MangoIcon,
    subListClass: "hide",
    subList: []
  },
  {
    title: "CANCELLATION POLICY",
    path: "/compliance/cancel/",
    getSlot: false,
    image: ItemsIcon,
    subListClass: "hide",
    subList: []
  },
  {
    title: "CONTACT US",
    path: "/compliance/contact/",
    getSlot: false,
    image: UserIcon,
    subListClass: "hide",
    subList: []
  },
  
];
